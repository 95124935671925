import React from 'react';
import { Link } from 'react-router-dom';
import { webDefine } from '../../webDefine';

export default function CopyRightFooter() {
  return (
    <>
      <div>
        <span className="text-sm">
          Design and Developed by{' '}
          {/* <Link
            className="text-blue-3 hover:underline"
            to="https://techrefic.com/"
          >
            Techrefic Technologies Pvt Ltd
          </Link>{' '} */}
          {/* © 2024 -  */}
          {webDefine.title}
        </span>
      </div>
    </>
  );
}
