import axios from 'axios';
// import React from 'react';
import config from '../../Config/config';
import mydecodedTokenFunction from '../../utils/decodetoken';

export async function getpnlData(selectedDate, tab) {
  let getuserBots = localStorage.getItem('token');
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  try {
    let apiUrl = '';
    if (
      selectedDate[1] &&
      selectedDate[0] &&
      typeof selectedDate === 'object'
    ) {
      apiUrl = `${config.base_url}${tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-closed-orders-pnl/${userid?.id}?day=custom&start=${selectedDate[0]
        }&end=${selectedDate[1]}`;
    } else {
      apiUrl = `${config.base_url}${tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-closed-orders-pnl/${userid?.id}?day=today`;
    }
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${getuserBots}` },
    });
    return response?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function getpnlDataById(tab, request_id) {
  let getuserBots = localStorage.getItem('token');
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  try {
    let apiUrl = `${config.base_url}${tab === 'live' ? config.live_trade : config.virtual_trade
      }/getpnl/${request_id}`;
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${getuserBots}` },
    });
    return response?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function getClosedOrders({ queryKey }) {
  let getuserBots = localStorage.getItem('token');
  const [_key, { closedOrdersDate, page, tab }] = queryKey;
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  try {
    let apiUrl = '';
    if (closedOrdersDate[1] && closedOrdersDate[0]) {
      apiUrl = `${config.base_url}${tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-placed-orders/${userid.id
        }?limit=10&page=${page}&day=custom&start=${closedOrdersDate[0]}&end=${closedOrdersDate[1]
        }`;
    } else {
      apiUrl = `${config.base_url}${tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-placed-orders/${userid.id}?day=today&page=${page}`;
    }
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${getuserBots}` },
    });
    const orders = response.data;
    return orders;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

//Open-orders call
export async function getOpenOrders(tab) {
  let getuserBots = localStorage.getItem('token');
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  try {
    const response = await axios.get(
      `${config.base_url}${tab === 'live' ? config.live_trade : config.virtual_trade
      }/get-user-open-orders/${userid.id}`,
      {
        headers: { Authorization: `Bearer ${getuserBots}` },
      },
    );
    const ordersData = response?.data?.orders_data;
    const finalOrder = ordersData
      ?.filter((obj) => obj.quantity_left > 0)
      .map((item) => ({
        ...item.vt_entry,
        quantity_left: item.quantity_left,
      }));
    return { ordersData, finalOrder };
  } catch (error) {
    console.log(error);
  }
}

export async function subscribeStratagies(userid) {
  let getuserBots = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `${config.base_url}/strategy/get-user-strategies/${userid.id}`,
      {
        headers: { Authorization: `Bearer ${getuserBots}` },
      },
    );

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function getTradeOrders(Date, selectedDate, page, tab) {
  let getuserBots = localStorage.getItem('token');
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  try {
    let apiUrl = '';
    if (
      selectedDate[1] &&
      selectedDate[0] !== '' &&
      selectedDate[1] !== '' &&
      selectedDate[0]
    ) {
      apiUrl = `${config.base_url}${tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-closed-trades/${userid.id
        }?limit=10&page=${page}&day=custom&start=${selectedDate[0]}&end=${selectedDate[1]
        }`;
    } else {
      apiUrl = `${config.base_url}${tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-closed-trades/${userid.id}?limit=10&page=${page}&day=${Date}`;
    }
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${getuserBots}` },
    });
    const data = response.data;
    const finalData = response.data.orders_data;

    return { data, finalData };
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function getPortfolioTrades({ queryKey }) {
  let getuserBots = localStorage.getItem('token');
  const [_key, { tradesOrdersDate, page, tab }] = queryKey;
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  try {
    let apiUrl = '';
    if (tradesOrdersDate[1] && tradesOrdersDate[0]) {
      apiUrl = `${config.base_url}${tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-closed-trades/${userid.id
        }?limit=10&page=${page}&day=custom&start=${tradesOrdersDate[0]}&end=${tradesOrdersDate[1]
        }`;
    } else {
      apiUrl = `${config.base_url}${tab === 'live' ? config.live_trade : config.virtual_trade
        }/get-user-closed-trades/${userid.id}?limit=10&page=${page}&day=today`;
    }
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${getuserBots}` },
    });
    const data = response.data;
    const finalData = response.data.orders_data;

    return { data };
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
